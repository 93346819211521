import store from "@/store/index.js";
import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import { _ } from "vue-underscore";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  name: "reports",

  data() {
    return {
      SelectLeadsDate: "",
      SelectSalesDate: "",
      monthFormat: "MM",
      yearFormat: "YYYY",
      selectedYear: "",
      SalesStatus: "All",
      options: ["All", "Received", "Ticketed", "Closed"],
      TimeZoneOptions: ["EST", "UTC"],
      SaleTimeZone: "EST",
      leadTimeZone: "EST",
      saleExport: false,
      leadExport: false,
    };
  },
  mixins: [paginationMixin],
  components: {
    "header-list": headerList,
    "date-picker": DatePicker,
  },

  computed: {
    hasLeadReport() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "lead-report-export";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasSaleReport() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "sale-report-export";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
  },
  methods: {
    // getCurrentMonth() {
    //   const currentDate = new Date();
    //   const currentMonth = currentDate.getMonth() + 1;
    //   return currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
    // },
    // getCurrentYear() {
    //   const currentDate = new Date();
    //   return currentDate.getFullYear().toString();
    // },
    leadsExports() {
      let _vm = this;
      _vm.leadExport = true;
      const from_date = _vm.SelectLeadsDate[0];
      const to_date = _vm.SelectLeadsDate[1];
      const time_zone = _vm.leadTimeZone;
      store.state.isLoaderShow = true;
      _vm.axios
        .get(
          "export/lead-report" +
            "?from_date=" +
            from_date +
            "&to_date=" +
            to_date +
            "&time_zone=" +
            time_zone
        )
        .then(function (response) {
          let a = document.createElement("a");
          a.href = response.data.data.file;
          a.download = response.data.data.name;
          a.click();
          store.state.isLoaderShow = false;
          _vm.leadExport = false;
        });
    },
    salesExports() {
      let _vm = this;
      const from_date = _vm.SelectSalesDate[0];
      const to_date = _vm.SelectSalesDate[1];
      const time_zone = _vm.SaleTimeZone;
      const sale_status = _vm.SalesStatus;
      store.state.isLoaderShow = true;
      _vm.saleExport = true;
      _vm.axios
        .get(
          "export/sale-report" +
            "?from_date=" +
            from_date +
            "&to_date=" +
            to_date +
            "&time_zone=" +
            time_zone +
            "&sale_status=" +
            sale_status
        )
        .then(function (response) {
          let a = document.createElement("a");
          a.href = response.data.data.file;
          a.download = response.data.data.name;
          a.click();
          store.state.isLoaderShow = false;
          _vm.saleExport = false;
        });
    },
  },
  mounted() {
    // this.selectedMonth = this.getCurrentMonth();
    // this.selectedYear = this.getCurrentYear();
  },
};
